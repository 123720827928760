import React from "react";
import { graphql } from "gatsby";
import useScript from "../hooks/useScript";

import Layout from "../components/layout";
import { UiUxDesignerJsonLd } from "../components/jsonld/UiUxDesignerJsonLd";

// import IconCall from "../images/icons/theme/communication/call-1.svg";
// import IconMail from "../images/icons/theme/communication/send.svg";
//
// import LogoSamsung from "../images/logos/brand/grey-samsung.svg";
// import LogoRedbull from "../images/logos/brand/grey-hubspot.svg";
// import LogoHubspot from "../images/logos/brand/grey-red-bull.svg";
// import LogoTmobile from "../images/logos/brand/grey-t-mobile.svg";
// import IconCheck from "../images/icons/interface/check.svg";

const UiuxdesignerDePage = ({ data, location: { pathname } }) => (
  <Layout
    headerBgClass="bg-primary-alt"
    hideFooter
    footerBgClass="bg-white"
    meta={{ seo: data.datoCmsPage.seo, path: pathname }}
  >
    <UiUxDesignerJsonLd />
    <div className="contact-us-page">
      <div className="contact-us-page__bg bg-primary-alt">
        {/*<div className="divider">
          <Divider3 style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }} />
        </div>*/}
      </div>

      <section className="bg-primary-alt">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <h1 className="mb-5">
                Produktdesigner - UI UX Designer (w/m/d) – deutschsprachig
                <br />
                100% Home-Office
              </h1>

              <h2 className="mt-6 mb-3">Dein Weg und deine Aufgaben</h2>
              <p className="lead">Du sammelst Anforderungen von Kund*innen und Stakeholder*innen und weißt, wie man die UX-Verantwortung gemeinsam trägt. Du arbeitest eng mit den Teilhaber*innen zusammen und bindest sie in den Designprozess ein;</p>
              <p className="lead">Du weißt, wie du mit unseren User Researchers oder Kognitionswissenschaftler*innen kommunizierst, die Ergebnisse bearbeitest, Probleme neu formulierst und Ideen entwickelst. Du arbeitest während des gesamten Projekts mit den Forschenden zusammen;</p>
              <p className="lead">Du hast ein Gespür dafür, wann du Best Practices folgen und wann du auch einmal von ihnen abweichen solltest. Du hast gute Erfahrungen im Umgang mit komplexen Anwendungen. Du bist dir bewusst, dass Muster aus Produkten mit breiteren Funktionen nicht immer auf jene passen, die spezifischer sind;</p>
              <p className="lead">Du hilfst Kund*innen, den Wert der UX-Methodik zu verstehen und baust Vertrauen auf, indem du laufend mit ihnen kommunizierst. Du arbeitest nicht für sie, sondern mit ihnen;</p>
              <p className="lead">Dir ist klar, dass während der Ideen-Entwicklungsphase mehr MEHR ist. Dir liegt es, divergent zu denken. Dabei nutzt du die Chance, möglichst viele unterschiedliche Ideen zu generieren;</p>
              <p className="lead">Du gehörst zu den echten Design-Profis, aber deine Problemlösungsfähigkeiten  können auch auf jede andere Disziplin angewendet werden;</p>
              <p className="lead">Du bist erfinderisch in deiner Herangehensweise und verwendest manchmal Unkonventionelles zur Inspiration oder ungewöhnliche Methoden, um Herausforderungen zu bewältigen. In dieser Hinsicht sind User Experience, Interface oder visuelles Design ein Mittel, um umfassendere Ziele zu erreichen.</p>

              {/* <img
                className="mb-5"
                src="https://www.datocms-assets.com/16499/1583861795-remote-ui-ux-designer-job.png"
              />
               */}
              <h2 className="mt-6 mb-3">Was wir bieten</h2>
              
                <ul className="lead mb-5">
                <li className="mb-2">Arbeiten von Zuhause aus (für immer);</li>
                <li className="mb-2">Kein Mikromanagement im Arbeitsalltag - wir vertrauen dir;</li>
                <li className="mb-2">Arbeit an komplexen & herausfordernden Produkten. Du hast keine Lust, noch eine weitere Yoga-App oder einen Onboarding-Flow zu entwerfen? Wir auch nicht!</li>
                <li className="mb-2">Direktes Zusammenarbeiten mit einem*r Kognitionswissenschaftler*in und UX-Forscher*in. Kein Pseudo-UX oder Rätselraten mehr;</li>
                <li className="mb-2">Direkte Kommunikation mit Kunden;</li>
                <li className="mb-2">Unterstützung bei deiner Talententwicklung, um beruflich zu wachsen - du steckst nicht mehr in deiner Position oder deinem Gehalt fest.</li>
                </ul>
              {/* <p className="lead mb-2">
                <strong>Responsibilities:</strong>
              </p>
              <ul className="lead mb-5">
                <li>Conduct UX Audit & Heuristic Evaluation</li>
                <li>Define concepts according to tasks and business requirements</li>
                <li>Create UX Strategies</li>
                <li>Lo-fi sketches</li>
                <li>Hi-fi Wireframes</li>
                <li>Clickable prototypes</li>
                <li>UI Concepts & Mood boards</li>
                <li>Hi-fi UI</li>
                <li>GUI Library</li>
                <li>Specs for Devs</li>
              </ul> */}

              {/* <p className="lead mb-2">
                <strong>Requirements:</strong>
              </p>
              <ul className="lead mb-5">
                <li>5+ year of UX or Product design hands-on experience</li>
                <li>Experience designing data heavy / B2B products</li>
                <li>English C1</li>
                <li>Proficiency in Figma</li>
                <li>
                  Being a problem-solver. You love finding out you're wrong.
                </li>
                <li>
                  You consume large amounts of qualitative and quantitative data
                  to constantly refine your assumptions.
                </li>
                <li>
                  Communicating about any questions/problems/ideas/suggestions,
                  asking for feedback and sharing feedback easily.
                </li>
                <li>
                  Being in charge of tasks, to know and tell others about
                  ability or disability to fit the time limit
                </li>
              </ul> */}

              {/* <p className="lead mb-2">
                <strong>Benefits:</strong>
              </p>
              <ul className="lead mb-5">
                <li className="mb-1">100% Remote</li>
                <li className="mb-1">No micromanagement</li>
                <li className="mb-1">Balanced workload</li>
                <li className="mb-1">Access to our Talent Development Program</li>
              </ul> */}

              <h2 className="mt-6 mb-3">Dein Profil</h2>
              <ul className="lead mb-5">
                <li className="mb-2">Du hast 5+ Jahre Erfahrung als digitale*r Produktdesigner*in;</li>
                <li className="mb-2">Du hast bereits komplexe und stark datenlastige Apps entworfen;</li>
                <li className="mb-2">Deutschkenntnisse: Mindestens B2;</li>
                <li className="mb-2">Englischkenntnisse: Mindestens B2;</li>
                <li className="mb-2">Du hast Talent und Erfahrung, wie man gut kommuniziert.</li>
              </ul>

            </div>

{/*             <div className="col-12 col-md-8">
              <div className="card card--common card-body card--no-hover">
                <div id="activecampaign-form">
                  <div id="activecampaign-form__wrapper">
                    <div className=" _form_21"></div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>

    {useScript("https://adamfard48700.activehosted.com/f/embed.php?id=21")}
  </Layout>
);

export default UiuxdesignerDePage;

export const query = graphql`
  query uiuxdesignerDePage {
    datoCmsPage(slug: { eq: "ui-ux-designer-job-de" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }
  }
`;
